import './index.scss'

let $picEl = $('[data-responsive-pic]')

// init
change()

$(window).on('resize', function () {
  change()
})

function change () {
  $picEl.each((i, el) => {
    let isPc = innerWidth > 768
    let isMobile = !isPc
    let $el = $(el)
    let isImg = $el[ 0 ].nodeName === 'IMG'
    
    if (isImg) {
      let src = $el.attr('src')
      let hasMobilePath = src.indexOf('/image/mobile') !== -1
      let newSrc = isPc ?
        src.replace('/image/mobile/', '/image/') :
        hasMobilePath ?
          src :
          src.replace('/image/', '/image/mobile/')
      
      $el.attr('src', newSrc)
    }
    else {
      let bgi = $el.css('background-image')
      let hasMobilePath = bgi.indexOf('/image/mobile') !== -1
      let newBgi = isPc ?
        bgi.replace('/image/mobile/', '/image/') :
        hasMobilePath ?
          bgi :
          bgi.replace('/image/', '/image/mobile/')
      
      $el.css('background-image', newBgi)
    }
  })
}
