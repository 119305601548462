import './index.scss'

import 'jquery.nicescroll/dist/jquery.nicescroll.min'

let $descElArr = $('.cmedia-text-desc')

// 包装内容
$descElArr.each(function (i, el) {
  let $el = $(el)
  let $wrapperEl = $('<div class="cmedia-text-content"></div>')
  
  // set content
  $wrapperEl.html($el.html())
  $el.html('').append($wrapperEl)
})

$descElArr.children().niceScroll({
  cursorcolor: '#cba03a',
  cursorborder: 'none',
  cursorwidth: '3px'
})