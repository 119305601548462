import './index.scss'

import 'jquery.mmenu/dist/jquery.mmenu.all'
import 'jquery.mmenu/dist/jquery.mmenu.all.css'

let $menuEl = $('.cmenu')
let $haederHamburgerEl = $('.cheader .hamburger')
let mmenu = null

$menuEl.mmenu({
  slidingSubmenus: false,
  extensions: [
    'pagedim-black',
    'position-back',
    'position-bottom',
    'border-full'
  ],
  navbar: {
    title: '海洋'
  }
})

mmenu = $menuEl.data('mmenu')

$haederHamburgerEl.click(function () {
  if ($haederHamburgerEl.hasClass('active')) {
    mmenu.close()
  }
  else {
    mmenu.open()
  }
})
mmenu.bind('close:before', function () {
  $haederHamburgerEl.removeClass('active')
})
mmenu.bind('open:before', function () {
  $haederHamburgerEl.addClass('active')
})
